import React from "react"
import { BsPencilSquare } from "react-icons/bs";
import { GiOpenBook } from "react-icons/gi";
import { FaCode } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Web Development",
    text: `We specalize in website design and web app development. We work with you to create an end user product to be proud of. `,
    linkText: 'Learn More',
    link: '/services/',
    
  },
  {
    id: 2,
    icon: <GiOpenBook className="service-icon" />,
    title: "Marketing",
    text: `We offer third party integrations for heat maps and analytics. We also offer monthly SEO reports & advice.`,
    linkText: 'Learn More',
    link: '/services/',
    linkNewTab: '_blank'
  },
  {
    id: 3,
    icon: <BsPencilSquare className="service-icon" />,
    title: "Design",
    text: `We offer custom logo design, image editing and video editing at a reasonable price. `,
    linkText: 'Learn More',
    link: '/services/',
    linkNewTab: '_blank'
  },
]
