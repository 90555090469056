import React from "react"
import Title from "./Title"
import Slider from "react-slick";
import Pic1 from "../assets/laptopmock2.png"
import Pic2 from '../assets/laptopmockWestmorelandA.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function MissionStatement() {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    initialSlide: 0,
    centerMode: false,
    dontAnimate: true,
    useTransform: false,
    lazyLoad: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: false,
        }
      }
    ]
  };
  const slidesData = [
    {
      id: 1,
      title: 'Nizhoni Properties LLC',
      imageName: Pic1,
      desc: 'Nizhoni Properties LLC is a real estate agency in Catalina Arizona. They contacted us for a new website and logo. They wanted to integrate with the third party software they were using to manage buildings and tenants. We connected their buildium data to their website so they could market their homes & apartments more efficiently.'
    }, {
      id: 2,
      title: 'Westmoreland Aviation',
      imageName: Pic2,
      desc: 'Westmoreland Aviation requested a new website to help boost SEO (Search Engine Optimization). By using a Static Site Generator with an open source Content Managment System we were not only able to help them get much better search results but we also cut some unnecessary Word Press costs.'
    },
  ];


  return (
    <section className="bg-black">
      <div className="spacerTest1 layer1"></div>
      <Title title={"Recent Projects"}/>
      <div className="section-center">
      <Slider {...settings}>
        {slidesData.map((slide) =>
          <div className="slick-slide" key={slide.id}>
            <h6 className="slick-slide-title">{slide.title}</h6>
            <img className="slick-slide-image" src={slide.imageName} />
            <div className="caroLabel">
              <p1>{slide.desc}</p1>
            </div>
          </div>
        )}
      </Slider>
      </div>
      <div className="spacerTestRotate layerRotate"></div>
    </section>
    )
}
