import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import MissionStatement from "../components/MissionStatement"
import ContactForm from "../components/ContactForm"
import SEO from "../components/SEO"


const Index = () => {

  return (
  <>
  <SEO title="Home" />
    <Layout>
      <Hero />
      <Services />
      <MissionStatement />
      <ContactForm />
    </Layout>
  </>
  )
}

export default Index
