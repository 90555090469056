import React from "react"
import Title from "./Title"
import services from "../constants/services"
const Services = () => {
  return (
  <section className="section-padBot bg-whiteSmoke section-padTopx2">
  {/* <div className="spacerTest2 test2"></div>
  <div className="spacerTest2 test1"></div> */}
    <Title title="services"/>
    <div className="section-center services-center">
      {services.map((service)=>{
        const {id, icon, title, text, link, linkText, linkNewTab} = service
        return (
          <article key={id} className="service">
            {icon}
            <h4>{title}</h4>
            <div className="underline"></div>
            <p>{text}</p>
            <a className="btn center-btnOptin" target="{linkNewTab}" rel="noreferrer" href={link}>{linkText}</a>
          </article>
        )
      })}
    </div>
  </section>
  )
}


export default Services
