import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import Typewriter from 'typewriter-effect';
import "../css/main.css"


const Hero = () => {
  
return ( 
  <section>
    <div className="hero-container">
      <div className="heroTextOverlay">
        <center>
          <h1 className="heroText">ARLOK TECHNOLOGIES</h1>
          <h2 className="heroSubText">Let's work together on</h2>
          <Typewriter
            options={{
              strings: ['Website Development', 'App Development', 'Marketing'],
              autoStart: true,
              loop: true,
              wrapperClassName: 'cursor'
            }}
          />
        </center>
      </div>
      <StaticImage
      alt="Hero Image for Arlok Tech"
      className="backgroundImage"
      style={{position: "absolute"}}
      src="../assets/heroTest2.jpeg"
      />
  </div>
  </section>
  )
}

export default Hero
